import { EPayCalcState, IPayCalcVM } from '@types';
import useApi from 'api/transportLayer';

export function filterPayCalcList(
  paycalcListData: IPayCalcVM[] = [],
  getArchived: boolean = false,
): {
  publishedPaycalc?: IPayCalcVM;
  draftPaycalc?: IPayCalcVM;
  publishPendingPaycalc?: IPayCalcVM;
  archivedPaycalcs?: IPayCalcVM[];
} {
  const draftPaycalc = paycalcListData.find((item) => item.status === EPayCalcState.DRAFT);
  const publishPendingPaycalc = paycalcListData.find((item) => item.status === EPayCalcState.PUBLISH_PENDING);
  const publishedPaycalc = paycalcListData.find((item) => item.status === EPayCalcState.PUBLISHED);
  if (!getArchived) {
    return { publishedPaycalc, draftPaycalc, publishPendingPaycalc };
  }
  const archivedPaycalcs = paycalcListData.filter((item) => item.status === EPayCalcState.ARCHIVED);
  return { publishedPaycalc, draftPaycalc, publishPendingPaycalc, archivedPaycalcs };
}

export const usePaycalcs = (planId: number, enabled = true, getArchived = false, isHr = false) => {
  const statusesToFetch = [EPayCalcState.PUBLISHED, EPayCalcState.DRAFT, EPayCalcState.PUBLISH_PENDING];
  if (getArchived) {
    statusesToFetch.push(EPayCalcState.ARCHIVED);
  }
  const getPaycalcResult = isHr
    ? useApi.Hr.Paycalc.getMany(
        {
          plan: planId,
          status: statusesToFetch,
        },
        { enabled: enabled && !!planId },
      )
    : useApi.Admin.Plan.PayCalc.getMany(
        {
          plan: planId,
          status: statusesToFetch,
        },
        { enabled: enabled && !!planId },
      );

  const { publishedPaycalc, draftPaycalc, publishPendingPaycalc, archivedPaycalcs } = filterPayCalcList(
    getPaycalcResult.data?.results,
    getArchived,
  );
  // The "working" paycalc is the draft if it exists, or one pending publish, otherwise
  // the current published paycalc.  The working paycalc is what is displayed on the
  // paycalc tab, and the ID which should be sent if creating a new item.
  const workingPaycalc = draftPaycalc ?? publishPendingPaycalc ?? publishedPaycalc;

  return {
    getPaycalcResult,
    draftPaycalc,
    publishPendingPaycalc,
    publishedPaycalc,
    workingPaycalc,
    archivedPaycalcs,
  };
};
