import { Trigger } from '@radix-ui/react-collapsible';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/collapsible#trigger
 */
interface IProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const DropdownTrigger = ({ className = '', children, ...props }: IProps) => {
  return (
    <Trigger asChild className={className} data-testid="dropdown-trigger">
      {children}
    </Trigger>
  );
};
