import FeatherIcon, { IconName } from '@components/FeatherIcon';
import colors from '@constants/colors';
import { useIdentity } from 'contexts/auth-context';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction } from 'react';
import { NavLink } from 'router';
import { styled } from 'stitches.config';

import { SettingsNavSectionWithDropdown } from '../SettingsNavSectionWithDropdown';
import styles from '../VerticalNavigationLayout.module.scss';
export interface INavItem {
  key: string;
  label: string;
  href?: string;
  iconName?: IconName;
  exact?: boolean;
  role?: string;
  featureFlagged?: boolean;
  subSections?: ISettingsNavSubSectionItem[];
  groups?: string[];
}
export interface INavItemsProps {
  navItems: INavItem[];
  isDrawerOpen?: boolean;
  toggleDrawer?: Dispatch<SetStateAction<boolean>>;
}
export interface ISettingsNavSubSectionItem {
  key: string;
  label: string;
  href: string;
}

const StyledLink = styled('a', {
  cursor: 'pointer',
  marginInlineStart: '10%',
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px',
  '& svg': {
    color: '$grey400',
  },
  '& > span': {
    marginBlockStart: '$2',
    paddingInlineStart: '$8',
    color: '$grey400',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$white',
        borderTopLeftRadius: 40,
        borderBottomLeftRadius: 40,
        '& > span': {
          color: '$primaryPeacockDark',
        },
        '& svg': {
          stroke: '$primaryPeacockDark',
        },
      },
    },
  },
});

const NavItems = ({ isDrawerOpen, navItems, toggleDrawer }: INavItemsProps) => {
  const router = useRouter();
  /**
   *filtering navItems array:
   * if flag is on, we show new settings dropdown and NOT company profile link
   * if flag is off, we show company profile link as expected
   * msd 12/01
   */
  const {
    state: { me },
  } = useIdentity();
  const filteredNavItems = navItems.filter((navItem) => {
    if (me.isPayroll && !me.isHr && navItem.key === 'settings') {
      const filteredSubSections = navItem.subSections.filter((section) => {
        return section.key === 'payPeriods';
      });
      navItem.subSections = filteredSubSections;
      return navItem;
    } else if (me.isHr || me.isPayroll) {
      return navItem.key !== 'profile';
    }
    return navItem;
  });

  return (
    <>
      {filteredNavItems.map(({ key, href, label, iconName, exact, featureFlagged = false, subSections = [] }) => {
        const showDropDownVariant = subSections.length && featureFlagged;

        if (key === 'settings' && showDropDownVariant) {
          return (
            <SettingsNavSectionWithDropdown
              navItems={subSections}
              label={label}
              key={key}
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
            />
          );
        } else if (key === 'templates') {
          return (
            <Link passHref key={key} href={href} legacyBehavior>
              <StyledLink active={href === router.pathname} data-testid={`vertical-nav-link-${key}`}>
                {isDrawerOpen ? (
                  <>
                    <FeatherIcon name={iconName} />
                    <span>{label}</span>
                  </>
                ) : (
                  <FeatherIcon name={iconName} />
                )}
              </StyledLink>
            </Link>
          );
        }

        return (
          <NavLink key={key} activeClassName={styles.activeNavItem} href={href} exact={exact}>
            <a className={styles.navItemContainer} data-testid={`vertical-nav-link-${key}`}>
              {isDrawerOpen ? (
                <>
                  <FeatherIcon name={iconName} color={colors.tableHeaderGrey} />
                  <span className={styles.navItemLabel}>{label}</span>
                </>
              ) : (
                <FeatherIcon name={iconName} color={colors.grey3} />
              )}
            </a>
          </NavLink>
        );
      })}
    </>
  );
};

export default NavItems;
