import React from 'react';
import { theme } from 'stitches.config';

interface IProps extends React.SVGProps<SVGSVGElement> {
  height?: number;
  width?: number;
  color?: string;
  desc?: string;
}

const Lightning = ({
  width = 24,
  height = 24,
  color = theme.colors.secondarySky as unknown as string,
  desc,
  ...rest
}: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 32 32" {...rest}>
      <title>Benefit Suggestions</title>
      <desc id="lightning-desc">{desc || 'A lightning bolt icon'}</desc>
      <g fill={color} className="nc-icon-wrapper">
        <path
          fill={color}
          d="M27,13h-9.743l2.718-11.775c0.104-0.453-0.116-0.917-0.533-1.122c-0.417-0.206-0.919-0.097-1.214,0.261 l-14,17c-0.246,0.299-0.297,0.712-0.132,1.062S4.613,19,5,19h9.743l-2.718,11.775c-0.104,0.453,0.116,0.917,0.533,1.122 C12.699,31.966,12.85,32,13,32c0.292,0,0.578-0.127,0.772-0.364l14-17c0.246-0.299,0.297-0.712,0.132-1.062S27.387,13,27,13z"
        ></path>
      </g>
    </svg>
  );
};

export default Lightning;
