export const initialState = {
  modalVisible: false,
  processingMultipleDateSpans: false,
  selectedPayCalcItem: null,
  currentBenefitResult: null,
  continueProcessingPaycalcItems: false,
  benefits: [],
  currentBenefitContext: null,
};

export enum EActions {
  SET_MODAL_VISIBLE = 'setModalVisible',
  CONTINUE_PROCESSING_PAYCALC_ITEMS = 'continueProcessingPaycalcItems',
  SET_BENEFITS = 'setBenefits',
  PROCESS_NEXT_PAYCALC_ITEM = 'processNextPaycalcItem',
  SET_CURRENT_BENEFIT_CONTEXT = 'setCurrentBenefitContext',
  RESET_AFTER_SUBMIT_OR_CLOSE = 'resetAfterSubmitOrClose',
  INCREASE_BENEFIT_PAYCALC_ITEM_INDEX = 'increaseBenefitPaycalcItemIndex',
}

const calculateDerivedState = (state) => {
  const processingMultipleDateSpans = state.currentBenefitContext?.hasMultipleDateSpans || false;

  return {
    ...state,
    processingMultipleDateSpans,
  };
};

export const reducer = (state, action) => {
  let newState;
  switch (action.type) {
    case EActions.RESET_AFTER_SUBMIT_OR_CLOSE: {
      newState = {
        ...state,
        modalVisible: false,
        selectedPayCalcItem: null,
      };
      break;
    }
    case EActions.SET_BENEFITS: {
      newState = {
        ...state,
        benefits: action.payload,
      };
      break;
    }
    case EActions.INCREASE_BENEFIT_PAYCALC_ITEM_INDEX: {
      if (state.currentBenefitContext) {
        state.currentBenefitContext.currIndex += 1;
      }
      newState = {
        ...state,
        currentBenefitContext: state.currentBenefitContext,
      };
      break;
    }
    case EActions.PROCESS_NEXT_PAYCALC_ITEM: {
      const benefit = state?.benefits?.find((benefit) => benefit?.id === state?.currentBenefitResult?.id) || {};
      const paycalcItemAddedIndex = state?.currentBenefitResult?.suggestedBenefitMatchIndex;
      const nextUncreatedIndex = paycalcItemAddedIndex?.indexOf(0, state?.currentBenefitContext?.currIndex);
      const nextItem = benefit?.paycalcItems?.[nextUncreatedIndex];

      if (!nextItem || !state?.continueProcessingPaycalcItems) {
        newState = {
          ...state,
          selectedPayCalcItem: null,
          currentBenefitContext: null,
          modalVisible: false,
          continueProcessingPaycalcItems: false,
        };
      } else {
        const moreItemsToBeAdded = paycalcItemAddedIndex?.indexOf(0, nextUncreatedIndex + 1);
        newState = {
          ...state,
          selectedPayCalcItem: nextItem,
          currentBenefitContext: benefit,
          modalVisible: true,
          continueProcessingPaycalcItems: moreItemsToBeAdded > 0,
        };
      }
      break;
    }
    case EActions.SET_CURRENT_BENEFIT_CONTEXT: {
      const { benefitResult, paycalcItem, context } = action.payload;
      newState = {
        ...state,
        currentBenefitContext: context,
        currentBenefitResult: benefitResult,
        selectedPayCalcItem: paycalcItem,
        continueProcessingPaycalcItems: benefitResult?.hasMultipleDateSpans,
      };
      break;
    }
    case EActions.SET_MODAL_VISIBLE: {
      newState = {
        ...state,
        modalVisible: true,
      };
      break;
    }
    default:
      return state;
  }
  return { ...calculateDerivedState(newState) };
};
