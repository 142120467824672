import useApi from '@api/transportLayer';
import { AlertDialog } from '@components/v2';
import EligibleBenefitsSection from '@screens/Admin/PlanDetailsPage/Sidebar/PlanBenefitsCard/EligibleBenefitsSection';
import IneligibleBenefitsSection from '@screens/Admin/PlanDetailsPage/Sidebar/PlanBenefitsCard/IneligibleBenefitsSection';
import { IBenefitResultVM, IPlanAdminVM } from '@types';
import { useIdentity } from 'contexts/auth-context';
import { usePaycalcs } from 'hooks/usePaycalcs';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Router } from 'router';

interface IProps {
  benefits: IBenefitResultVM[];
  plan: IPlanAdminVM;
  refetchBenefits: () => void;
}

const PlanBenefitsCard = ({ benefits, plan, refetchBenefits }: IProps) => {
  const removalSuccess = () => {
    setRemoveBenefit(undefined);
    Router.push(`/plan?planId=${plan.id}&tab=PROGRAMS`, undefined, { shallow: true });
    setFetchPaycalc(true);
  };

  const { mutate: removePayCalcItem, isLoading: paycalcDeleteLoading } = useApi.Admin.Plan.PayCalcItem.delete({
    onSuccess: () => {
      removalSuccess();
    },
    onError: (error) => {
      toast.error(error.data?.detail);
    },
  });

  const { mutate: bulkRemovePayCalcItem } = useApi.Admin.Plan.PayCalcItem.bulk_delete({
    onSuccess: () => {
      removalSuccess();
    },
    onError: (error) => {
      toast.error(error.data?.detail);
    },
  });

  const {
    state: { me },
  } = useIdentity();

  const eligibleBenefits = benefits.filter((benefit) => benefit.eligible);
  const ineligibleBenefits = benefits.filter((benefit) => !benefit.eligible && benefit.reasonsIneligible?.length > 0);

  const [fetchPaycalc, setFetchPaycalc] = useState(true);
  const { getPaycalcResult, workingPaycalc } = usePaycalcs(plan.id, fetchPaycalc);

  const [removeBenefit, setRemoveBenefit] = useState<IBenefitResultVM>();

  const clearBenefitsMap: { [key: number]: number[] } = {};
  ineligibleBenefits.forEach((benefit) => {
    const itemsAddedToWorkingPaycalc = workingPaycalc?.items.filter((i) => i.result === benefit.id);
    const isItemAdded = !!itemsAddedToWorkingPaycalc?.length;
    if (isItemAdded && !clearBenefitsMap[benefit.id]) {
      clearBenefitsMap[benefit.id] = itemsAddedToWorkingPaycalc.map((item) => item.id);
    }
  });

  // add in unused but still eligible benefits to the clear benefit map
  eligibleBenefits.forEach((benefit) => {
    const itemsAddedToWorkingPaycalc = workingPaycalc?.items.filter((i) => i.result === benefit.id);
    const isItemAddedButNotSuggested = !!itemsAddedToWorkingPaycalc?.length && !benefit.paycalcItems.length;
    if (isItemAddedButNotSuggested && !clearBenefitsMap[benefit.id]) {
      clearBenefitsMap[benefit.id] = itemsAddedToWorkingPaycalc.map((item) => item.id);
    }
  });

  const removePaycalcItem = (benefit: IBenefitResultVM) => {
    if (clearBenefitsMap[benefit.id]?.length === 1) {
      removePayCalcItem({ paycalcItemId: clearBenefitsMap[benefit.id][0] });
    } else {
      bulkRemovePayCalcItem({ paycalc: workingPaycalc?.id, itemIds: clearBenefitsMap[benefit.id] });
    }
  };

  return (
    <>
      {!!eligibleBenefits?.length && (
        <EligibleBenefitsSection
          benefits={eligibleBenefits}
          plan={plan}
          workingPaycalc={workingPaycalc}
          setFetchPaycalc={setFetchPaycalc}
          getPaycalcResult={getPaycalcResult}
          refetchBenefits={refetchBenefits}
          onRemovePayCalcItem={(benefit) => {
            // NOTE: need to set this to false so that the paycalcs are re-fetched
            setFetchPaycalc(false);
            setRemoveBenefit(benefit);
          }}
        />
      )}
      {!!ineligibleBenefits?.length && (
        <IneligibleBenefitsSection
          benefits={ineligibleBenefits}
          workingPaycalc={workingPaycalc}
          onRemovePayCalcItem={(benefit) => {
            // NOTE: need to set this to false so that the paycalcs are re-fetched
            setFetchPaycalc(false);
            setRemoveBenefit(benefit);
          }}
        />
      )}

      {removeBenefit && (
        <AlertDialog
          title={`Remove ${removeBenefit.rule.benefitTitle}`}
          description={
            'This benefit item is no longer applicable to the leave. Would you like to remove the pay calc item?'
          }
          onClose={() => setRemoveBenefit(undefined)}
          onAction={() => removePaycalcItem(removeBenefit)}
          actionLabel="remove"
          onCancel={() => setRemoveBenefit(undefined)}
          cancelLabel="cancel"
          loading={paycalcDeleteLoading}
        />
      )}
    </>
  );
};

export default React.memo(PlanBenefitsCard);
