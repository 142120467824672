import useApi from '@api/transportLayer';
import FeatherIcon from '@components/FeatherIcon';
import IconButton from '@components/IconButton';
import Dismiss from '@components/Icons/Dismiss';
import colors from '@constants/colors';
import { EMessageStatus, IMessageVM, IUserVM } from '@types';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { Popover } from 'react-tiny-popover';
import { useLocalStorage } from 'usehooks-ts';

import styles from './styles.module.scss';
import BellEmpty from '@public/assets/bell-empty-notifications.png';
import Image from 'next/legacy/image';
interface IProps {
  user: IUserVM;
  iconClassName?: string;
}

const NotificationsDropDown = ({ user, iconClassName = '' }: IProps) => {
  const [notificationsLastReadAt, setNotificationsLastReadAt] = useLocalStorage<string | null>(
    'notificationsLastRead',
    null,
  );
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const { mutateAsync: updateOne } = useApi.EE.Notification.updateOne();
  const { mutateAsync: updateMany } = useApi.EE.Notification.Batch.updateMany();
  const { data, refetch: refetchNotifications } = useApi.EE.Notification.getMany(
    {
      app: true,
    },
    { refetchInterval: 60000 },
  );
  const notifications = data?.results ?? [];

  const newNotificationsToRead = React.useMemo(() => {
    if (!user) return false;
    else if (notifications.length === 0) return false;
    else if (!notificationsLastReadAt) return true;
    else return notifications.some((message) => moment(message.createdAt).format() > notificationsLastReadAt);
  }, [user, notificationsLastReadAt, notifications]);

  const onCloseNotificationsPopover = () => {
    setIsPopoverOpen(false);
    setNotificationsLastReadAt(moment().format());
  };

  const onClickNotificationsLink = () => {
    if (isPopoverOpen) {
      setNotificationsLastReadAt(moment().format());
    }
    setIsPopoverOpen(!isPopoverOpen);
  };

  const dismissNotification = async (message: IMessageVM) => {
    await updateOne({ messageId: message.id, status: EMessageStatus.DISMISSED });
    await refetchNotifications(); // refetch for now
  };

  const dismissAllNotifications = async () => {
    if (notifications.length === 0) return;

    await updateMany(notifications.map((message) => ({ id: message.id, status: EMessageStatus.DISMISSED })));
    await refetchNotifications(); // refetch for now
  };

  const lastIndex = notifications.length - 1;
  const messagesBody = notifications.map((message, idx) => {
    return (
      <div key={idx}>
        <div className={styles.message}>
          {moment(message.createdAt).format() > notificationsLastReadAt && <div className={styles.unread} />}
          <div className={styles.messageContentWrapper}>
            <span className={styles.messageBody} dangerouslySetInnerHTML={{ __html: message.body }}></span>
            <span className={styles.messageSendDate}>Sent {moment(message.createdAt).format('L')}</span>
          </div>
          <IconButton
            icon={Dismiss}
            className={styles.dismissButton}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              dismissNotification(message);
            }}
          />
        </div>
        {idx < lastIndex && <div className={styles.divider} />}
      </div>
    );
  });

  return (
    <Popover
      isOpen={isPopoverOpen}
      onClickOutside={onCloseNotificationsPopover}
      positions={['bottom']}
      align={'end'}
      content={
        <div className={styles.notificationsContainer}>
          <div className={styles.notificationsHeader}>Notifications</div>
          <div className={styles.divider} />
          <div className={styles.notificationsBody}>
            {notifications.length > 0 ? (
              messagesBody
            ) : (
              <div className={styles.empty}>
                <Image className={styles.emptyImage} src={BellEmpty} objectFit="contain" />
                <span className={styles.mainText}>You&apos;re all caught up!</span>
                <span className={styles.additionalText}>
                  We&apos;ll let you know when we have something new for you.
                </span>
              </div>
            )}
          </div>
          <div className={styles.divider} />
          <div className={styles.notificationsFooter}>
            <button className={styles.dismissAllButton} onClick={dismissAllNotifications}>
              Dismiss All
            </button>
          </div>
        </div>
      }
      // Keep on top of other items (ex. search bar)
      containerStyle={{ zIndex: '3' }}
    >
      <div onClick={onClickNotificationsLink} className={cx(styles.notificationsButton, iconClassName)}>
        <FeatherIcon name="Bell" title="View Notifications" color={colors.iconGrey} />
        {newNotificationsToRead && (
          <div className={styles.badgeContainer}>
            <div className={styles.notificationBadge}></div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default NotificationsDropDown;
