import { Root } from '@radix-ui/react-collapsible';
import React, { Dispatch, SetStateAction } from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/collapsible#root
 */
interface IProps {
  className?: string;
  defaultOpen?: boolean;
  open: boolean;
  disabled?: boolean;
  children?: React.ReactChild | React.ReactChild[];
  onOpenChange?: Dispatch<SetStateAction<boolean>>;
}

export const DropdownRoot = ({
  className = '',
  children,
  defaultOpen = false,
  open = false,
  disabled = false,
  onOpenChange = () => {},
}: IProps) => {
  return (
    <Root
      defaultOpen={defaultOpen}
      className={className}
      open={open}
      disabled={disabled}
      data-testid="dropdown-root"
      onOpenChange={onOpenChange}
    >
      {children}
    </Root>
  );
};
