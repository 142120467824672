import useApi from '@api/transportLayer';
import Combobox from '@components/v2/Combobox';
import { ISearchResultVM } from '@types';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Router } from 'router';

interface IOption {
  label: string;
  value: number;
}

const GlobalSearch = () => {
  const [results, setResults] = useState<ISearchResultVM[]>([]);
  const [searchString, setSearchString] = useState('');

  const {
    data: searchResults,
    error: searchError,
    refetch: fetchSearch,
    isFetching,
    isIdle,
    isSuccess,
    isError,
  } = useApi.Admin.Search.getResults({ searchString }, { enabled: false });

  const navigateResult = (searchResult: ISearchResultVM) => {
    if (searchResult) {
      Router.push(searchResult.relativePath);
    }
  };

  const resultToOptions = (results: ISearchResultVM[]) =>
    results?.map((result: ISearchResultVM, index): IOption => ({ label: result.representation, value: index }));

  const debounceRef = React.useRef(null);
  if (!debounceRef.current) {
    debounceRef.current = debounce(
      (query) => {
        setSearchString(query);
      },
      250,
      { leading: false, trailing: true },
    );
  }

  useEffect(() => {
    if (searchString) {
      if (isIdle && !isFetching) {
        fetchSearch();
      }
      if (isSuccess && searchResults.length) {
        setResults(searchResults);
      }
      if (isError && searchError) {
        toast.error('There was an error with your search. Please try again.');
      }
    }
  }, [fetchSearch, isError, isFetching, isIdle, isSuccess, searchError, searchResults, searchString]);

  return (
    <Combobox
      id="global-search"
      onInputChange={debounceRef.current}
      items={searchResults?.length ? resultToOptions(searchResults) : results.length ? resultToOptions(results) : []}
      onChange={(index) => navigateResult(results[index])}
      placeholder="Search or jump to..."
    />
  );
};

export default GlobalSearch;
