import FeatherIcon from '@components/FeatherIcon';
import NotificationsDropDown from '@components/NotificationsDropDown';
import colors from '@constants/colors';
import { ErrorBoundary } from '@sentry/nextjs';
import { HELP_PAGE } from '@constants/index';
import { useIdentity } from 'contexts/auth-context';
import React from 'react';
import { NavLink } from 'router';

import styles from '../VerticalNavigationLayout.module.scss';
import GlobalSearch from './components/GlobalSearch';

const PageHeader = () => {
  const {
    state: { me },
  } = useIdentity();

  return (
    <div className={styles.pageHeader}>
      <div className={styles.searchBar}>
        {me?.superuser && (
          <ErrorBoundary fallback={<p>Oops! Something went wrong, please reach out to support.</p>}>
            <GlobalSearch />
          </ErrorBoundary>
        )}
      </div>
      {me && (
        <div className={styles.userSection}>
          <div className={styles.navLink}>
            <FeatherIcon href={HELP_PAGE} title="Help" name="HelpCircle" color={colors.iconGrey} />
            <NotificationsDropDown iconClassName={styles.settingsLink} user={me} />
          </div>
          <div className={styles.navLink}>
            <NavLink href={me.isHr || me.isPayroll ? '/hr/settings' : '/settings'}>
              <a className={styles.settingsLink}>
                <FeatherIcon name="Settings" color={colors.tableHeaderGrey} />
                <span className={styles.navLinkLabel}>
                  <div className={styles.userLabel}>
                    <div className={styles.userName}>
                      {me.firstName} {me.lastName}
                    </div>
                    <div className={styles.userEmail}>{me.email}</div>
                  </div>
                </span>
              </a>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
