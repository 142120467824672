import useApi, { useQueryClient } from '@api/transportLayer';
import FeatherIcon from '@components/FeatherIcon';
import PayCalcItemModal from '@components/PlanCalendar/PayCalcItemModal';
import Tooltip from '@components/Tooltip';
import { Button, DefDescription, DefList, DefTitle } from '@components/v2';
import colors from '@constants/colors';
import { DjangoList, IBenefitResultVM, IPayCalcItemVM, IPayCalcVM, IPlanAdminVM } from '@types';
import QueryKeys from 'api/queryKeys';
import { useIdentity } from 'contexts/auth-context';
import { isEqual, isNil, omit } from 'lodash';
import React, { Dispatch, SetStateAction, useReducer, useState } from 'react';
import { toast } from 'react-hot-toast';
import { UseQueryResult } from 'react-query';
import { Router } from 'router';
import { styled } from 'stitches.config';
import { NavActions, useNavigationData } from 'contexts/right-flyout-context';

import styles from '../../Sidebar.module.scss';
import { EActions, initialState, reducer } from '../reducer';

const PlusIcon = styled(FeatherIcon, {
  fill: '$primaryPeacock',
  stroke: '$white',
  width: 18,
  height: 18,
});

export interface IFormattedBenefit extends IBenefitResultVM {
  needsToBeAdded?: boolean;
  needsToBeUpdated?: boolean;
  continueAdding?: boolean;
  manuallyEdited?: boolean;
  needsToBeRemoved?: boolean;
  additionalItemsCreated?: boolean;
  suggestedBenefitMatchIndex?: number[];
}

enum EBenefitItemStatus {
  MISSING,
  EDITED,
  UPDATED,
  ADDED,
}

function compareItems(itemA, itemB, ignoreList) {
  return isEqual(omit(itemA, ignoreList), omit(itemB, ignoreList));
}

function compareEqualLengthLists(suggestedItemList, attachedItemList, suggestedBenefitIndex, ignoreList) {
  for (let i = 0; i < suggestedItemList.length; i++) {
    const suggestedItem = suggestedItemList[i];
    const attachedItem = attachedItemList[i];
    const compResult = compareItems(attachedItem, suggestedItem, ignoreList);
    if (compResult) {
      suggestedBenefitIndex[i] = EBenefitItemStatus.ADDED;
    } else if (attachedItem.automated) {
      suggestedBenefitIndex.fill(EBenefitItemStatus.UPDATED);
      break;
    } else {
      suggestedBenefitIndex[i] = EBenefitItemStatus.EDITED;
    }
  }
  return suggestedBenefitIndex;
}

export const formatBenefitsForDisplay = (
  benefits: IBenefitResultVM[],
  workingPaycalc: IPayCalcVM,
  clearBenefitsMap: { [key: string]: number[] },
) =>
  benefits.map((benefit: IBenefitResultVM): IFormattedBenefit => {
    const formattedBenefit: IFormattedBenefit = { ...benefit };
    const attachedBenefitPaycalcItems = workingPaycalc?.items
      .filter((i: IPayCalcItemVM) => i.result === benefit.id)
      .sort((a, b) => (a.fromField < b.fromField ? -1 : 1));
    const isAddedToWorkingPaycalc = !!attachedBenefitPaycalcItems?.length;
    const bkeGeneratedPaycalcItems = attachedBenefitPaycalcItems?.filter((i: IPayCalcItemVM) => i.automated);
    const manuallyEditedPaycalcItems = attachedBenefitPaycalcItems?.filter((i: IPayCalcItemVM) => !i.automated);
    formattedBenefit.needsToBeUpdated = false;
    formattedBenefit.needsToBeAdded = false;
    formattedBenefit.continueAdding = false;
    formattedBenefit.manuallyEdited = false;
    formattedBenefit.additionalItemsCreated = false;
    const suggestedPaycalcItems = benefit.paycalcItems;
    // eslint-disable-next-line prefer-const
    let suggestedBenefitIndex = new Array(suggestedPaycalcItems.length).fill(EBenefitItemStatus.MISSING);

    const ignoreList = ['id', 'paycalc', 'paycalcId', 'stats', 'status'];
    const ignoreAutomatedList = [...ignoreList, 'automated'];

    if (!suggestedPaycalcItems.length) {
      if (isAddedToWorkingPaycalc) {
        formattedBenefit.needsToBeRemoved = true;
      }
      return formattedBenefit;
    }

    if (!isAddedToWorkingPaycalc) {
      // pass. it hasn't been added at all, so leave the array as all 0s
    } else if (bkeGeneratedPaycalcItems.length > suggestedPaycalcItems.length) {
      // we have too many automated items attached so lets force an update
      suggestedBenefitIndex.fill(EBenefitItemStatus.UPDATED);
    } else if (manuallyEditedPaycalcItems?.length) {
      if (bkeGeneratedPaycalcItems.length == 0) {
        // there's items from the benefit but all have been edited
        suggestedBenefitIndex.fill(EBenefitItemStatus.EDITED);
      } else if (attachedBenefitPaycalcItems?.length === suggestedPaycalcItems?.length) {
        // if they are equal, lets see if they all match regardless of automated value
        suggestedBenefitIndex = compareEqualLengthLists(
          suggestedPaycalcItems,
          attachedBenefitPaycalcItems,
          suggestedBenefitIndex,
          ignoreAutomatedList,
        );
      } else if (bkeGeneratedPaycalcItems.length < suggestedPaycalcItems.length) {
        // not enough automated items, so lets see if we need to continue or update bke items
        for (let i = 0; i < bkeGeneratedPaycalcItems.length; i++) {
          let matchFound = false;
          const attachedItem = bkeGeneratedPaycalcItems[i];
          for (let j = 0; j < suggestedPaycalcItems.length; j++) {
            const suggestedItem = suggestedPaycalcItems[j];
            const compResult = compareItems(attachedItem, suggestedItem, ignoreAutomatedList);
            if (compResult) {
              suggestedBenefitIndex[j] = EBenefitItemStatus.ADDED;
              matchFound = true;
              break;
            }
          }
          if (!matchFound) {
            suggestedBenefitIndex.fill(EBenefitItemStatus.UPDATED);
            break;
          }
        }
        // its possible to end loop with all attached items as automated, so we leave rest as 0s so we continue
      } else {
        // means we have same number of bke items and suggested items, so also additional items
        // lets see if our suggested and bke ones still align or need an update
        suggestedBenefitIndex = compareEqualLengthLists(
          suggestedPaycalcItems,
          bkeGeneratedPaycalcItems,
          suggestedBenefitIndex,
          ignoreList,
        );
        formattedBenefit.additionalItemsCreated = true;
      }
    } else if (bkeGeneratedPaycalcItems?.length < suggestedPaycalcItems?.length) {
      // lets see if all automated ones still match the suggeseted or if we need an update
      for (let i = 0; i < bkeGeneratedPaycalcItems.length; i++) {
        let matchFound = false;
        const attachedItem = bkeGeneratedPaycalcItems[i];
        for (let j = 0; j < suggestedPaycalcItems.length; j++) {
          const suggestedItem = suggestedPaycalcItems[j];
          const compResult = compareItems(attachedItem, suggestedItem, ignoreList);
          if (compResult) {
            suggestedBenefitIndex[j] = EBenefitItemStatus.ADDED;
            matchFound = true;
            break;
          }
        }
        if (!matchFound) {
          suggestedBenefitIndex.fill(EBenefitItemStatus.UPDATED);
          break;
        }
      }
      // if suggestedBenefitIndex has a 0 still, we know we need to continue
    } else {
      suggestedBenefitIndex = compareEqualLengthLists(
        suggestedPaycalcItems,
        bkeGeneratedPaycalcItems,
        suggestedBenefitIndex,
        ignoreList,
      );
    }

    if (suggestedBenefitIndex.some((index) => index == EBenefitItemStatus.UPDATED)) {
      formattedBenefit.needsToBeUpdated = true;
      // we need to update aka delete the old automated and then add all the new ones
      suggestedBenefitIndex.fill(EBenefitItemStatus.MISSING);
      if (!clearBenefitsMap[benefit.id]) {
        clearBenefitsMap[benefit.id] = bkeGeneratedPaycalcItems.map((item) => item.id);
      }
    } else if (suggestedBenefitIndex.some((index) => index == EBenefitItemStatus.EDITED)) {
      formattedBenefit.manuallyEdited = true;
      // we reset the edited ones to 0s so we re-add them
      for (let x = 0; x < suggestedBenefitIndex.length; x++) {
        if (suggestedBenefitIndex[x] == EBenefitItemStatus.EDITED) {
          suggestedBenefitIndex[x] = EBenefitItemStatus.MISSING;
        }
      }
    } else if (suggestedBenefitIndex.some((index) => index == EBenefitItemStatus.MISSING)) {
      if (suggestedBenefitIndex.some((index) => index == EBenefitItemStatus.ADDED)) {
        formattedBenefit.continueAdding = true;
      } else {
        formattedBenefit.needsToBeAdded = true;
      }
    }
    // if it passes all these checks, we will display the black arrow

    formattedBenefit.suggestedBenefitMatchIndex = suggestedBenefitIndex;
    return formattedBenefit;
  });

interface IProps {
  benefits: IBenefitResultVM[];
  plan: IPlanAdminVM;
  workingPaycalc: IPayCalcVM;
  getPaycalcResult: UseQueryResult<DjangoList<IPayCalcVM>, unknown>;
  setFetchPaycalc: Dispatch<SetStateAction<boolean>>;
  refetchBenefits: () => void;
  onRemovePayCalcItem: (benefit: IBenefitResultVM) => void;
}

const EligibleBenefitsSection = ({
  benefits,
  plan,
  workingPaycalc,
  getPaycalcResult,
  setFetchPaycalc,
  refetchBenefits,
  onRemovePayCalcItem,
}: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { dispatch: navigationDispatch } = useNavigationData();
  const clearBenefitsMap = {};
  const queryClient = useQueryClient();
  const [computedData, setComputedData] = useState<boolean>(false);
  const [updatingComputedData, setUpdatingComputedData] = useState<boolean>(false);
  const {
    state: { me },
  } = useIdentity();

  const { mutate: createPayCalcItem } = useApi.Admin.Plan.PayCalcItem.create({
    onSuccess: (data) => {
      refetchBenefits();
      queryClient.invalidateQueries([QueryKeys.AdminPlanPaycalcMany]);
      dispatch({
        type: EActions.INCREASE_BENEFIT_PAYCALC_ITEM_INDEX,
      });
      dispatch({
        type: EActions.PROCESS_NEXT_PAYCALC_ITEM,
        payload: { justProccessedId: data.id },
      });
      navigationDispatch({
        type: NavActions.SET_PROCESSED_BENEFITS,
      });
      return;
    },
    onError: (error) => {
      toast.error(error.data?.detail);
    },
  });

  const payCalcItemTemplates = benefits
    .filter((benefit) => benefit?.rule?.template)
    .map((benefit) => benefit.rule.template);

  async function onPayCalcItemCreated(payCalcItem: IPayCalcItemVM) {
    const initialFormValues = state.selectedPayCalcItem;
    const fieldsWePrecalculate = ['from', 'to', 'payPerPeriod'];

    function fieldValueHasChanged(fieldName) {
      const initialValue = initialFormValues && initialFormValues[fieldName];
      if (isNil(initialValue) || initialValue == '0') {
        // Presumably, we want to ignore any fields for which we didn't have an initial calculated value
        return false;
      }
      const finalValue = payCalcItem[fieldName];
      if (initialValue !== finalValue) {
        return true;
      }
      return false;
    }

    const transformedValues = {
      ...payCalcItem,
      toField: payCalcItem.to.toString(),
      fromField: payCalcItem.from.toString(),
      type: 'GENERIC',
      payPerPeriod: payCalcItem.payPerPeriod.toString(),
      maxPayPerPeriod: payCalcItem.maxPayPerPeriod.toString(),
      payPercentage: payCalcItem.payPercentage.toString(),
      maxDuration: payCalcItem.maxDuration.toString(),
    };

    if (getPaycalcResult.status !== 'success') await getPaycalcResult.refetch();
    delete transformedValues['paycalcId'];
    if (workingPaycalc?.id) transformedValues['paycalc'] = workingPaycalc.id;
    else delete transformedValues['paycalc'];

    if (clearBenefitsMap[payCalcItem.result]) {
      const idsToWipe = clearBenefitsMap[payCalcItem.result];
      transformedValues['idsToExclude'] = idsToWipe;
      delete clearBenefitsMap[payCalcItem.result];
    }

    createPayCalcItem({ ...transformedValues, plan: plan.id });
    Router.push(`/plan?planId=${plan.id}&tab=PROGRAMS`, undefined, { shallow: true });

    dispatch({
      type: EActions.RESET_AFTER_SUBMIT_OR_CLOSE,
    });
  }

  function onModalClose() {
    setFetchPaycalc(false);
    dispatch({
      type: EActions.RESET_AFTER_SUBMIT_OR_CLOSE,
    });
  }

  const generatePaycalcItem = ({ template, item, benefitId }) => {
    const paycalcItem = {
      ...template,
      paycalcItemTemplateId: template.id,
      precomputed: true,
      automated: true,
      result: benefitId,
      ...item,
      template: {
        color: template.color,
        description: template.description,
      },
      id: null,
    };
    if (!plan?.leave?.expectedLeaveDate || paycalcItem.from < plan?.leave?.expectedLeaveDate) {
      paycalcItem.from = null;
      paycalcItem.to = null;
      paycalcItem.fromField = null;
      paycalcItem.toField = null;
    }
    return paycalcItem;
  };

  const generatePayCalcItems = (benefitResult: IBenefitResultVM) => {
    const paycalcArray: IPayCalcItemVM[] = [];
    const template = benefitResult?.rule?.template;
    const benefitId = benefitResult?.id;
    if (!benefitResult?.paycalcItems || benefitResult?.paycalcItems.length === 0) {
      const paycalcItem = generatePaycalcItem({ template, benefitId, item: {} });
      paycalcArray.push(paycalcItem);
    } else {
      for (const item of benefitResult.paycalcItems) {
        const paycalcItem = generatePaycalcItem({ template, benefitId, item });
        paycalcArray.push(paycalcItem);
      }
    }
    return paycalcArray;
  };

  const onModalOpen = (benefitResult: IFormattedBenefit) => {
    let benefitItemContext = state.benefits.find((benefit) => benefit.id === benefitResult.id);

    // use the array to find the suggested paycalc item that has not yet been added
    let paycalcItemIndex = 0;
    for (let i = 0; i < benefitResult.paycalcItems.length; i++) {
      if (benefitResult.suggestedBenefitMatchIndex[i] == EBenefitItemStatus.MISSING) {
        paycalcItemIndex = i;
        benefitResult.suggestedBenefitMatchIndex[i] = EBenefitItemStatus.ADDED;
        break;
      }
    }

    // NOTE: this ternary is kinda crazy but keeps us from passing an undefined paycalc to the modal
    // if the benefit needs to be added, use the paycalc item from the benefit
    // if the benefit needs to be updated, use the paycalc item from the working paycalc
    // if the working paycalc doesn't have the benefit, use the paycalc item from the benefit
    const paycalcItem: IPayCalcItemVM = benefitResult.needsToBeAdded
      ? benefitResult.paycalcItems[0]
      : benefitResult.needsToBeUpdated
      ? benefitResult.paycalcItems[paycalcItemIndex]
      : benefitItemContext?.paycalcItems[paycalcItemIndex];

    dispatch({
      type: EActions.SET_MODAL_VISIBLE,
    });
    setFetchPaycalc(true);

    // creating a new benefit pay calc item, so there's no pay calc item benefit on the state
    if (!benefitItemContext) {
      benefitItemContext = paycalcItem;
    }
    if (benefitItemContext) {
      benefitItemContext.currIndex = paycalcItemIndex;
    }

    dispatch({
      type: EActions.SET_CURRENT_BENEFIT_CONTEXT,
      payload: {
        benefitResult,
        paycalcItem,
        context: benefitItemContext,
      },
    });

    if (benefitResult.needsToBeUpdated) {
      setUpdatingComputedData(true);
    } else {
      setUpdatingComputedData(false);
    }

    if (benefitResult.paycalcItems[0]) {
      setComputedData(true);
    }
  };

  const nextPaycalcItemToBeAdded = state.currentBenefitResult?.hasMultipleDateSpans
    ? state.currentBenefitContext?.currIndex + 1
    : 1;
  const nOfXPayCalcsString = state.currentBenefitResult?.hasMultipleDateSpans
    ? `(${nextPaycalcItemToBeAdded} of ${state?.currentBenefitContext?.numOfPaycalcItems})`
    : '';

  const formattedBenefits = formatBenefitsForDisplay(benefits, workingPaycalc, clearBenefitsMap);
  if (formattedBenefits?.length > 0 && state?.benefits?.length === 0) {
    const benefitsWithContext = formattedBenefits.map((benefit) => {
      const paycalcItems = generatePayCalcItems(benefit);
      const numOfPaycalcItems = paycalcItems.length;
      const hasMultipleDateSpans = benefit.hasMultipleDateSpans;

      return {
        id: benefit.id,
        paycalcItems,
        hasMultipleDateSpans,
        currIndex: 0,
        numOfPaycalcItems,
      };
    });
    dispatch({
      type: EActions.SET_BENEFITS,
      payload: benefitsWithContext,
    });
  }
  return (
    <>
      <DefList css={{ marginBlockEnd: '$16' }}>
        <DefTitle>Eligible To Apply For</DefTitle>
        {formattedBenefits.map((benefit) => (
          <DefDescription key={benefit.id}>
            <span>{benefit.rule.benefitTitle}</span>

            {benefit.needsToBeAdded || benefit.needsToBeUpdated || benefit.continueAdding || benefit.manuallyEdited ? (
              <Button
                css={{ padding: 0 }}
                color={'textPeacock'}
                className={styles.spanLink}
                onClick={() => {
                  onModalOpen(benefit);
                }}
              >
                {benefit.needsToBeAdded ? (
                  <PlusIcon name="PlusCircle" />
                ) : benefit.needsToBeUpdated ? (
                  'Update'
                ) : benefit.manuallyEdited ? (
                  'Edited'
                ) : benefit.continueAdding ? (
                  'Continue'
                ) : (
                  ''
                )}
              </Button>
            ) : benefit.needsToBeRemoved ? (
              <Button
                color={'textPeacock'}
                onClick={() => {
                  onRemovePayCalcItem(benefit);
                }}
              >
                Remove
              </Button>
            ) : !benefit.paycalcItems.length ? (
              <div>N/A</div>
            ) : benefit.additionalItemsCreated ? (
              <Tooltip
                content={'We found more items on the paycalc for this benefit than suggested by BKE'}
                direction="top"
                hoverShowDelayMs={50}
                dark={true}
              >
                <FeatherIcon name="AlertTriangle" size={18} strokeWidth={'2.5'} color={colors.ripplingYellow} />
              </Tooltip>
            ) : (
              <FeatherIcon name="ChevronRight" size={18} strokeWidth={'2'} color={colors.tableHeaderGrey} />
            )}
          </DefDescription>
        ))}
      </DefList>
      {state.selectedPayCalcItem && (
        <PayCalcItemModal
          open={state.modalVisible}
          plan={plan}
          payCalcItem={state.selectedPayCalcItem}
          onClose={onModalClose}
          onSubmit={onPayCalcItemCreated}
          payCalcItemTemplates={payCalcItemTemplates}
          isTemplate={false}
          computedData={computedData}
          updatingBenefitResult={updatingComputedData}
          multiplePayCalcsHeader={nOfXPayCalcsString}
          preventCloseOnSubmit={state.processingMultipleDateSpans}
        />
      )}
    </>
  );
};

export default EligibleBenefitsSection;
