import { Content } from '@radix-ui/react-collapsible';
import React from 'react';

/**
 * https://www.radix-ui.com/docs/primitives/components/collapsible#content
 */
interface IProps {
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
}

export const DropdownContent = ({ className = '', children }: IProps) => {
  return (
    <Content className={className} data-testid="dropdown-content">
      {children}
    </Content>
  );
};
