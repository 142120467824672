import FeatherIcon from '@components/FeatherIcon';
import { Text } from '@components/v2/StyleGuide/ColorGuide/components/Text';
import { ContentType, NavActions, useNavigationData } from 'contexts/right-flyout-context';
import React from 'react';
import { styled } from 'stitches.config';

const HeaderContainer = styled('div', {
  display: 'flex',
  padding: '$16',
  justifyContent: 'space-between',
  borderBottom: '1px solid $grey200',
  alignItems: 'center',
});
const CloseIcon = styled('button', {
  border: 'none',
  background: 'transparent',
  padding: 0,
  lineHeight: 0,
});
const XIcon = styled(FeatherIcon, {
  cursor: 'pointer',
  color: '$black',
});
const Header = () => {
  const {
    dispatch,
    state: { type },
  } = useNavigationData();
  const header =
    type === ContentType.PROFILE
      ? 'Profile Details'
      : type === ContentType.BIRTH_DETAILS
      ? 'Birth Details'
      : 'Benefit Suggestions';
  return (
    <HeaderContainer>
      <Text TextType="nunito16" css={{ fontFamily: '$Poppins' }}>
        {header}
      </Text>
      <CloseIcon onClick={() => dispatch({ type: NavActions.CLOSE })}>
        <XIcon width={16} height={16} name="X" />
      </CloseIcon>
    </HeaderContainer>
  );
};

export default Header;
