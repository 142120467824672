import useApi from '@api/transportLayer';
import FeatherIcon from '@components/FeatherIcon';
import Logo from '@components/Logo';
import colors from '@constants/colors';
import { googleLogout } from '@react-oauth/google';
import RightFlyout from '@screens/Admin/PlanDetailsPage/components/RightFlyout';
import shouldBeLoggedIn from '@utils/should-be-logged-in';
import cx from 'classnames';
import { KUSTOMER_CONTACT_FORM } from '@constants/index';
import { useIdentity } from 'contexts/auth-context';
import { NavActions, useNavigationData } from 'contexts/right-flyout-context';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'router';
import { styled } from 'stitches.config';
import Image from 'next/legacy/image';
import NavItems, { INavItem } from './navigation';
import PageHeader from './pageHeader';
import styles from './VerticalNavigationLayout.module.scss';
import { FLAG_ENABLE_HR_ACCESS_CONTROLS, FLAG_ENABLE_LSM_ACCESS_CONTROLS } from '@constants/flags';
import { EGroupName } from '@types';
// import CollapsedIcon from '@public/logo.svg';

const LSM_CONTROLLED_GROUPS = [EGroupName.ADMIN, EGroupName.LSM, EGroupName.LEADS];
const HR_CONTROLLED_GROUPS = [EGroupName.HR_ADMIN, EGroupName.HR_USER];

export function filterNavItems(
  navItems: INavItem[],
  me: any,
  userFlags: Record<string, boolean>,
  userGroups: Array<{ name: string }>,
): INavItem[] {
  if (!userFlags || (!userFlags[FLAG_ENABLE_LSM_ACCESS_CONTROLS] && !userFlags[FLAG_ENABLE_HR_ACCESS_CONTROLS])) {
    return navItems.filter((i) => !i.role || !!me[i.role]);
  }

  return navItems.filter((i) => {
    const hasRole = !i.role || !!me[i.role];
    if (!i.groups || !hasRole) return hasRole;

    if (
      userFlags[FLAG_ENABLE_LSM_ACCESS_CONTROLS] &&
      i.groups.some((group) => LSM_CONTROLLED_GROUPS.includes(group as EGroupName))
    ) {
      return userGroups.some((group) => i.groups.includes(group.name));
    } else if (
      userFlags[FLAG_ENABLE_HR_ACCESS_CONTROLS] &&
      i.groups.some((group) => HR_CONTROLLED_GROUPS.includes(group as EGroupName))
    ) {
      return userGroups.some((group) => i.groups.includes(group.name));
    }
    return hasRole;
  });
}

interface IProps {
  navItems: INavItem[];
  children: React.ReactNode;
}

const Container = styled('div', {
  backgroundColor: '$grey100',
  overflowX: 'hidden',
  display: 'grid',
  height: '100vh',
  gridTemplateColumns: '274px auto 86px',
  // is there a better way to do this?
  variants: {
    collapsedNavNoRightNav: {
      true: {
        gridTemplateColumns: '72px auto',
      },
    },
    collapsedNavRightNavClosed: {
      true: {
        gridTemplateColumns: '72px auto 86px',
      },
    },
    collapsedNavRightNavOpen: {
      true: {
        gridTemplateColumns: '72px auto 385px',
      },
    },
    openNoRightNav: {
      true: {
        gridTemplateColumns: '274px auto',
      },
    },
    openRightNavClosed: {
      true: {
        gridTemplateColumns: '274px auto 86px',
      },
    },
    openRightNavOpen: {
      true: {
        gridTemplateColumns: '274px auto 385px',
      },
    },
  },
});

const LeftNav = styled('div', {
  width: '72px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBlockStart: '$24',
  transition: 'width 0.4s',
  variants: {
    open: {
      true: {
        width: '274px',
      },
    },
  },
});

const Content = styled('div', {
  backgroundColor: '$white',
  overflow: 'auto',
  '& .banner': {
    margin: '$24 $24 0 $24',
  },
});

const VerticalNavigationLayout = ({ children, navItems: _navItems }: IProps) => {
  const {
    dispatch: navigationDispatch,
    state: { plan, open },
  } = useNavigationData();
  const router = useRouter();
  const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
  const [filteredNavItems, setFilteredNavItems] = useState<INavItem[]>(_navItems);
  const {
    dispatch: authDispatch,
    state: { me },
  } = useIdentity();
  const { data: userFlags } = useApi.Flags.getUserFlags(
    {
      userId: me.id,
      [FLAG_ENABLE_LSM_ACCESS_CONTROLS]: '',
      [FLAG_ENABLE_HR_ACCESS_CONTROLS]: '',
    },
    { enabled: !!me },
  );
  const { mutateAsync: logout } = useApi.Auth.logout({
    onSuccess: () => finishLogout(),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    const planId = router.query.planId;
    if (!planId && plan) {
      navigationDispatch({ type: NavActions.SET_PLAN });
    }
  }, [router, navigationDispatch, plan]);

  const userGroups = me?.groups || [];

  useEffect(() => {
    setFilteredNavItems(filterNavItems(_navItems, me, userFlags, userGroups));
  }, [me, _navItems, userGroups, userFlags]);

  function onToggleMenuClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.stopPropagation();
    event.preventDefault();
    setNavigationOpen(!navigationOpen);
  }

  const finishLogout = useCallback(() => {
    authDispatch({ type: 'SET_ME', payload: null });
    googleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return (
    <Container
      openNoRightNav={navigationOpen && !plan}
      openRightNavOpen={navigationOpen && !!plan && open}
      openRightNavClosed={navigationOpen && !!plan && !open}
      collapsedNavRightNavOpen={!navigationOpen && !!plan && open}
      collapsedNavRightNavClosed={!navigationOpen && !!plan && !open}
      collapsedNavNoRightNav={!navigationOpen && !plan}
    >
      <LeftNav open={navigationOpen}>
        <div className={styles.navigationContainer}>
          <div className={styles.menuContainer} onClick={onToggleMenuClick}>
            <Logo width={'100%'} height={navigationOpen ? 64 : 50} className="logo" />
          </div>
          <div className={styles.navItemsContainer}>
            <NavItems isDrawerOpen={navigationOpen} navItems={filteredNavItems} toggleDrawer={setNavigationOpen} />
          </div>
        </div>
        <div className={styles.navItemsContainer}>
          <NavLink href={KUSTOMER_CONTACT_FORM} absolute={true}>
            <a className={cx(styles.navItemContainer, styles.contactUsButton)} target="_blank">
              {navigationOpen ? (
                <>
                  <FeatherIcon name="Mail" color={colors.logoutGrey} />
                  <span className={styles.navItemLabel}>Contact Us</span>
                </>
              ) : (
                <FeatherIcon name="Mail" color={colors.logoutGrey} />
              )}
            </a>
          </NavLink>

          {navigationOpen ? (
            <button
              onClick={() => {
                logout({});
              }}
              className={cx(styles.navItemContainer, styles.logoutButton)}
            >
              <FeatherIcon name="LogOut" color={colors.logoutGrey} />
              <span className={styles.navItemLabel}>Log Out</span>
            </button>
          ) : (
            <button
              onClick={() => {
                logout({});
              }}
              className={cx(styles.navItemContainer, styles.logoutButton)}
            >
              <FeatherIcon name="LogOut" color={colors.logoutGrey} />
            </button>
          )}
        </div>
      </LeftNav>
      <Content>
        <PageHeader />
        {children}
      </Content>
      {plan && <RightFlyout />}
    </Container>
  );
};

export default shouldBeLoggedIn(VerticalNavigationLayout);
