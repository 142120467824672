import { Button, ButtonColor, DefList, DefTitle, ToggleSwitch } from '@components/v2';
import { StyledDD } from '@components/v2/Lists/DefList';
import PlanBenefitsCard from '@screens/Admin/PlanDetailsPage/Sidebar/PlanBenefitsCard';
import { EBirthType, IProfileVM } from '@types';
import { paidPerChoices } from '@constants/index';
import { ContentType, useNavigationData } from 'contexts/right-flyout-context';
import { format as dateFormat, parseISO, subDays } from 'date-fns';
import React from 'react';
import { styled } from 'stitches.config';

const LineItem = styled(StyledDD, {
  margin: 0,
  color: '$grey400',
  display: 'block',
  wordBreak: 'break-word',
});

type IProps = {
  impersonate: ({ userId, companyId }: { userId: number; companyId: number }) => void;
  employeeData: IProfileVM;
  updateDisplayBirthDetails: (checked: boolean) => void;
  refetchBenefits: () => void;
};
const Content = ({ impersonate, employeeData, updateDisplayBirthDetails, refetchBenefits }: IProps) => {
  const {
    state: { type, plan, showBirthDetails, benefits },
  } = useNavigationData();

  switch (type) {
    case ContentType.PROFILE:
      return (
        <DefList css={{ padding: '$24' }}>
          {plan?.employee && (
            <>
              <DefTitle>Employee Name</DefTitle>
              <LineItem>
                {plan.employee?.isArchived && `${plan?.employee.firstName} ${plan?.employee.lastName}`}
                {!plan.employee?.isArchived && (
                  <Button
                    color={ButtonColor.TEXT_PEACOCK}
                    css={{ fontWeight: '$semibold700', padding: 0 }}
                    onClick={() => impersonate({ userId: plan?.employee.id, companyId: plan?.companyId })}
                  >
                    {plan?.employee.firstName}&nbsp;{plan?.employee.lastName}
                  </Button>
                )}
              </LineItem>
              {plan.employee?.employeeId && (
                <>
                  <DefTitle>Employee ID</DefTitle>
                  <LineItem>{plan.employee?.employeeId}</LineItem>
                </>
              )}
              {employeeData?.remoteEntityId && (
                <>
                  <DefTitle>Entity ID</DefTitle>
                  <LineItem>{employeeData.remoteEntityId}</LineItem>
                </>
              )}
              <DefTitle>Communication Before Leave</DefTitle>
              <LineItem>{plan.leave?.communicationPreLeaveValue}</LineItem>
              <DefTitle>Communication During Leave</DefTitle>
              <LineItem>{plan.leave?.communicationLeaveValue}</LineItem>
              {(employeeData?.workingCity || employeeData?.workingState) && (
                <>
                  <DefTitle>Working Location</DefTitle>
                  <LineItem>{`${employeeData?.workingCity ? employeeData?.workingCity + ',' : ''} ${
                    employeeData?.workingState
                  }`}</LineItem>
                </>
              )}
            </>
          )}
          {plan?.manager && (
            <>
              <DefTitle>Manager Name</DefTitle>
              <LineItem>
                {plan.manager?.isArchived && `${plan.manager?.firstName} ${plan.manager?.lastName}`}
                {!plan.manager?.isArchived && (
                  <Button
                    color={'textPeacock'}
                    css={{ fontWeight: 700, padding: 0 }}
                    onClick={() => impersonate({ userId: plan.manager?.id, companyId: plan.companyId })}
                  >
                    {plan.manager?.firstName}&nbsp;{plan.manager?.lastName}
                  </Button>
                )}
              </LineItem>

              {plan.manager?.email && (
                <>
                  <DefTitle>Manager Email</DefTitle>
                  <LineItem>{plan.manager?.email}</LineItem>
                </>
              )}
            </>
          )}
          {employeeData && (
            <>
              {employeeData?.hireDate && (
                <>
                  <DefTitle>Date of Hire</DefTitle>
                  <LineItem>{dateFormat(parseISO(employeeData.hireDate), 'M/d/yyyy')}</LineItem>
                </>
              )}
              {employeeData?.salary && (
                <>
                  <DefTitle>Current Pay Rate</DefTitle>
                  <LineItem>
                    {`$${employeeData?.salary}/${
                      paidPerChoices[employeeData?.salaryPeriod]?.toLowerCase() || 'Unknown'
                    }`}
                  </LineItem>
                </>
              )}
              {plan.employee?.email && (
                <>
                  <DefTitle>Work Email</DefTitle>
                  <LineItem>{plan.employee?.email}</LineItem>
                </>
              )}
              {plan.employee?.personalEmail && (
                <>
                  <DefTitle>Personal Email</DefTitle>
                  <LineItem>{plan.employee?.personalEmail}</LineItem>
                </>
              )}
              {plan.employee?.phone && (
                <>
                  <DefTitle>Personal Phone Number (Cell)</DefTitle>
                  <LineItem>{plan.employee?.phone}</LineItem>
                </>
              )}
              <>
                <DefTitle>Enrolled in Company Benefits</DefTitle>
                <LineItem>{`${
                  [
                    employeeData.enrolledHealthBenefits,
                    employeeData.enrolledLtDisability,
                    employeeData.enrolledStDisability,
                  ].filter((b) => !!b).length
                } of 3`}</LineItem>
                <LineItem>
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: '0 0 0 14px',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                    }}
                  >
                    <li>
                      <input
                        type="checkbox"
                        name={'enrolledHealthBenefits'}
                        checked={employeeData?.enrolledHealthBenefits}
                        disabled
                        readOnly
                      />
                      <label style={{ paddingLeft: '8px' }}>Company Health Benefits</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name={'enrolledStDisability'}
                        checked={employeeData?.enrolledStDisability}
                        disabled
                        readOnly
                      />
                      <label style={{ paddingLeft: '8px' }}>Short-term Disability</label>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name={'enrolledLtDisability'}
                        checked={employeeData?.enrolledLtDisability}
                        disabled
                        readOnly
                      />
                      <label style={{ paddingLeft: '8px' }}>Long-term Disability</label>
                    </li>
                  </ul>
                </LineItem>
              </>
            </>
          )}
        </DefList>
      );
    case ContentType.BIRTH_DETAILS:
      return (
        <>
          {showBirthDetails && (
            <DefList css={{ padding: '$24' }}>
              <DefTitle>Last Day Worked</DefTitle>
              <LineItem>{dateFormat(subDays(parseISO(plan.leave?.expectedLeaveDate), 1), 'M/d/yyyy')}</LineItem>
              {plan.leave?.expectedDueDate && (
                <>
                  <DefTitle>{plan.leave.birthDetailsConfirmed ? 'Baby’s Date of Birth' : 'Expected Due Date'}</DefTitle>
                  <LineItem>{dateFormat(parseISO(plan.leave?.expectedDueDate), 'M/d/yyyy')}</LineItem>
                </>
              )}
              {plan.leave?.birthType ? (
                <>
                  <DefTitle>Type of Birth</DefTitle>
                  <LineItem css={{ borderBottom: '1px solid $grey200', paddingBlockEnd: '$24' }}>
                    {EBirthType[plan.leave?.birthType]}
                  </LineItem>
                </>
              ) : (
                <DefTitle>Non-Birthing Leave</DefTitle>
              )}
            </DefList>
          )}
          <DefList
            css={{
              paddingInline: '$24',
              display: 'flex',
              justifyContent: 'space-between',
              marginBlockStart: showBirthDetails ? '0' : '$16',
            }}
          >
            <DefTitle>Visibility</DefTitle>
            <ToggleSwitch
              id="birthDetailsToggle"
              ariaLabel="birth details switch"
              checked={showBirthDetails}
              onCheckedChange={updateDisplayBirthDetails}
            />
          </DefList>
        </>
      );
    case ContentType.BENEFITS:
      return (
        <DefList css={{ padding: '$24' }}>
          <PlanBenefitsCard benefits={benefits} plan={plan} refetchBenefits={refetchBenefits} />
        </DefList>
      );

    default:
      return null;
  }
};

export default Content;
