import colors from '@constants/colors';
import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
  stroke?: string;
  height?: number;
  width?: number;
  [key: string]: string | number;
}

const Dismiss = ({ width, height, stroke, ...props }: IProps) => (
  <svg width={width} height={width} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3L3 9" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 3L9 9" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

Dismiss.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
};

Dismiss.defaultProps = {
  width: 12,
  height: 12,
  stroke: colors.accentDark,
};

export default Dismiss;
