import useApi from '@api/transportLayer';
import { ELeaveType, ILeaveAdminVM } from '@types';
import { NavActions, useNavigationData } from 'contexts/right-flyout-context';
import React from 'react';
import { toast } from 'react-hot-toast';
import { styled } from 'stitches.config';

import Content from './FlyoutContent';
import Header from './Header';
import { BabyIcon, LightningIcon, ProfileIcon } from './Icons';

const MainContainer = styled('aside', {
  height: '100%',
  variants: {
    open: {
      true: {
        display: 'grid',
        gridTemplateColumns: '5.375rem auto',
      },
    },
  },
});

const Menu = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
});

const InfoContainer = styled('div', {
  backgroundColor: '$white',
});

const IconContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBlockStart: '$32',
  gap: '$8',
  alignItems: 'center',
});

const RightFlyout = () => {
  const { mutate: impersonate } = useApi.Auth.impersonate();
  const {
    dispatch,
    state: { open, plan },
  } = useNavigationData();

  const { data: employeeData } = useApi.Admin.User.Profile.getOne(
    { userId: plan?.employeeId },
    { enabled: !!plan?.employeeId },
  );
  const { mutate: editLeave } = useApi.Admin.Leave.update({
    onSuccess: (data: ILeaveAdminVM) => {
      dispatch({
        type: NavActions.SET_PLAN,
        payload: { plan: { ...plan, leave: { ...plan.leave, displayBirthDetails: data.displayBirthDetails } } },
      });
    },
    onError: () => {
      toast.error('Error updating Leave Display Birth Details state');
    },
  });

  const { refetch: refetchBenefits } = useApi.Benefit.Result.getMany(
    { leaveId: plan?.leave?.id },
    {
      enabled: false,
    },
  );

  const updateDisplayBirthDetails = (checked: boolean) => {
    dispatch({ type: NavActions.TOGGLE_BIRTH_DETAILS });
    editLeave({ displayBirthDetails: checked, leaveId: plan.leave?.id });
  };

  return (
    <MainContainer id="resizable" open={open}>
      <Menu>
        <IconContainer>
          <ProfileIcon />
          {plan.leave.leaveType === ELeaveType.PARENTAL && <BabyIcon />}
          <LightningIcon />
        </IconContainer>
      </Menu>
      {open && (
        <InfoContainer>
          <Header />
          <Content
            employeeData={employeeData}
            impersonate={impersonate}
            refetchBenefits={refetchBenefits}
            updateDisplayBirthDetails={updateDisplayBirthDetails}
          />
        </InfoContainer>
      )}
    </MainContainer>
  );
};

export default RightFlyout;
