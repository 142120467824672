import FeatherIcon from '@components/FeatherIcon';
import { DropdownContent, DropdownRoot, DropdownTrigger } from '@components/v2/Dropdown';
import colors from '@constants/colors';
import cx from 'classnames';
import { useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NavLink } from 'router';

import { INavItemsProps } from '../navigation';
import styles from './SettingsWithDropdown.module.scss';

export interface IProps extends INavItemsProps {
  label: string;
  isDrawerOpen: boolean;
  toggleDrawer?: Dispatch<SetStateAction<boolean>>;
}

const basePath = '/hr/settings/';
export const SettingsNavSectionWithDropdown = ({
  label,
  navItems,
  isDrawerOpen,
  toggleDrawer,
}: IProps): JSX.Element => {
  const { pathname } = useRouter();
  const [active, setActive] = useState(false);
  const [clicked, setClicked] = useState(false);
  const handleOnClick = () => {
    setActive((active) => !active);
    setClicked(() => true);

    if (!isDrawerOpen) {
      toggleDrawer(true);
    }
  };

  useEffect(() => {
    if (pathname.includes(basePath) && !active && !clicked) {
      setActive((active) => !active);
    }
    if (!isDrawerOpen && active) {
      setActive((active) => !active);
    }
  }, [active, pathname, clicked, isDrawerOpen]);

  return (
    <DropdownRoot className={styles.dropdownRoot} open={active}>
      <div className={styles.dropdownItem}>
        <div onClick={handleOnClick}>
          <div className={styles.dropdownHeader}>
            <DropdownTrigger className={styles.dropdownTrigger}>
              <div className={cx(styles.triggerSection, active && styles.active)}>
                <div className={styles.iconAndLabel}>
                  {active ? (
                    <FeatherIcon name="Settings" color={colors.peacockDark} />
                  ) : (
                    <FeatherIcon name="Settings" color={colors.tableHeaderGrey} />
                  )}
                  <div className={styles.label}>{isDrawerOpen ? label : null}</div>
                </div>
                {isDrawerOpen ? (
                  <FeatherIcon name="ChevronDown" color={colors.peacockDark} className={styles.chevron} />
                ) : null}
              </div>
            </DropdownTrigger>
          </div>
        </div>
        <DropdownContent className={styles.dropdownContent}>
          {navItems.map(({ key, href, label }) => (
            <NavLink key={key} activeClassName={styles.activeNavItem} href={href}>
              <a className={styles.navItemContainer}>
                <span className={styles.navItemLabel}>{label}</span>
              </a>
            </NavLink>
          ))}
        </DropdownContent>
      </div>
    </DropdownRoot>
  );
};
