import Lightning from '@components/Icons/Lightning';
import { ContentType, NavActions, useNavigationData } from 'contexts/right-flyout-context';
import React from 'react';
import { styled } from 'stitches.config';
import colors from '@constants/colors';

const Icon = styled('button', {
  position: 'relative',
  border: 'none',
  backgroundColor: 'transparent',
  padding: '$16',
  lineHeight: 0,
  '&:hover': {
    backgroundColor: '$secondarySkyLight',
    borderRadius: '$8',
  },
  '& svg.checkCircle, & svg.alert': {
    position: 'absolute',
    stroke: '$grey100',
    top: '8px',
    right: '3px',
    width: 16,
    height: 16,
  },
  variants: {
    open: {
      true: {
        backgroundColor: '$secondarySkyLight',
        borderRadius: '$8',
      },
    },
    iconType: {
      checkCircle: {
        '& svg.checkCircle': {
          '& g, & path': {
            fill: '$secondaryGrass',
          },
        },
      },
      alert: {
        '& svg.alert': {
          '& g, & path': {
            fill: '$primaryCoral',
          },
        },
      },
    },
  },
  cursor: 'pointer',
});

export const ProfileIcon = () => {
  const {
    dispatch,
    state: { type, open },
  } = useNavigationData();
  return (
    <Icon
      open={type === ContentType.PROFILE && open}
      data-testid="flyoutProfileIcon"
      onClick={() => dispatch({ type: NavActions.SET_OPEN, payload: { type: ContentType.PROFILE } })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 32 32">
        <title>Profile Details</title>
        <g fill={colors.secondarySky} className="nc-icon-wrapper">
          <path
            d="M31,9h-9c-.552,0-1,.448-1,1s.448,1,1,1h9c.552,0,1-.448,1-1s-.448-1-1-1Z"
            fill={colors.secondarySky}
            data-color="color-2"
          ></path>
          <path
            d="M31,15h-7c-.552,0-1,.448-1,1s.448,1,1,1h7c.552,0,1-.448,1-1s-.448-1-1-1Z"
            fill={colors.secondarySky}
            data-color="color-2"
          ></path>
          <path
            d="M31,21h-4c-.552,0-1,.448-1,1s.448,1,1,1h4c.552,0,1-.448,1-1s-.448-1-1-1Z"
            fill={colors.secondarySky}
            data-color="color-2"
          ></path>
          <circle cx="11.5" cy="8.5" r="6.5" fill={colors.secondarySky}></circle>
          <path
            d="M11.5,17C5.356,17,.316,21.811,.026,27.953c-.013,.273,.087,.54,.275,.738,.189,.198,.45,.31,.724,.31H21.975c.273,0,.535-.112,.724-.31,.188-.198,.288-.464,.275-.738-.29-6.142-5.33-10.953-11.474-10.953Z"
            fill={colors.secondarySky}
          ></path>
        </g>
      </svg>
    </Icon>
  );
};

export const BabyIcon = () => {
  const {
    dispatch,
    state: { type, open, plan },
  } = useNavigationData();
  return (
    <Icon
      open={type === ContentType.BIRTH_DETAILS && open}
      data-testid="flyoutBabyIcon"
      iconType="checkCircle"
      onClick={() => dispatch({ type: NavActions.SET_OPEN, payload: { type: ContentType.BIRTH_DETAILS } })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 32 32">
        <title>Birth Details</title>
        <g fill={colors.secondarySky} className="nc-icon-wrapper">
          <path
            d="M27,24V14h4a1,1,0,0,0,1-1V6a1,1,0,0,0-.375-.781L28.269,2.534A7.029,7.029,0,0,0,23.9,1H21A5,5,0,0,1,11,1H8.1A7.029,7.029,0,0,0,3.731,2.534L.375,5.219A1,1,0,0,0,0,6v7a1,1,0,0,0,1,1H5V24a7,7,0,0,1,7,7h8A7,7,0,0,1,27,24Zm-6.8-7.269L16,21l-4.2-4.269a2.8,2.8,0,0,1,0-3.919,2.7,2.7,0,0,1,4.2.428,2.7,2.7,0,0,1,4.2-.428A2.8,2.8,0,0,1,20.2,16.731Z"
            fill={colors.secondarySky}
          ></path>
        </g>
      </svg>
      {plan.leave.birthDetailsConfirmed && (
        <svg className="checkCircle" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <title>Birth Details Confirmed</title>
          <g fill="">
            <path
              d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16,16-7.163,16-16C31.974,7.174,24.826,.026,16,0Zm7.707,11.707l-10,10c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-4-4c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l3.293,3.293,9.293-9.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414Z"
              fill=""
            ></path>
          </g>
        </svg>
      )}
    </Icon>
  );
};

export const LightningIcon = () => {
  const {
    dispatch,
    state: { type, open, formattedBenefits },
  } = useNavigationData();
  return (
    <Icon
      iconType="alert"
      open={type === ContentType.BENEFITS && open}
      data-testid="flyoutLightningIcon"
      onClick={() => dispatch({ type: NavActions.SET_OPEN, payload: { type: ContentType.BENEFITS } })}
    >
      <Lightning />
      {formattedBenefits?.some((b) => b.needsToBeAdded) && (
        <svg className="alert" xmlns="http://www.w3.org/2000/svg" height="32" width="32" viewBox="0 0 32 32">
          <title>Has Eligible Benefits</title>
          <g fill="">
            <path
              d="M16,0C7.178,0,0,7.178,0,16s7.178,16,16,16,16-7.178,16-16S24.822,0,16,0Zm-1.492,6.5c0-.276,.224-.5,.5-.5h1.984c.276,0,.5,.224,.5,.5v12c0,.276-.224,.5-.5,.5h-1.984c-.276,0-.5-.224-.5-.5V6.5Zm1.492,19.5c-1.105,0-2-.895-2-2s.895-2,2-2,2,.895,2,2-.895,2-2,2Z"
              fill=""
            ></path>
          </g>
        </svg>
      )}
    </Icon>
  );
};
