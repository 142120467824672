import FeatherIcon from '@components/FeatherIcon';
import { Button, DefDescription, DefList, DefTitle } from '@components/v2';
import { Tooltip } from '@components/v2';
import colors from '@constants/colors';
import { IBenefitResultVM, IPayCalcItemVM, IPayCalcVM } from '@types';
import React from 'react';

import styles from '../../Sidebar.module.scss';

interface IProps {
  benefits: IBenefitResultVM[];
  workingPaycalc: IPayCalcVM;
  onRemovePayCalcItem: (benefit: IBenefitResultVM) => void;
}

const IneligibleBenefitsSection = ({ benefits, workingPaycalc, onRemovePayCalcItem }: IProps) => (
  <DefList>
    <DefTitle>Not Eligible</DefTitle>
    {benefits.map((benefit: IBenefitResultVM) => {
      const isItemAdded = workingPaycalc?.items.some((i: IPayCalcItemVM) => i.result === benefit.id);

      return (
        <DefDescription key={benefit.id} css={{ overflowWrap: 'anywhere', gap: '$8' }}>
          <span>{benefit.rule.benefitTitle}</span>
          {!!isItemAdded && (
            <Button
              color={'textPeacock'}
              onClick={() => {
                onRemovePayCalcItem(benefit);
              }}
            >
              Remove
            </Button>
          )}
          {!isItemAdded && !!benefit.reasonsIneligible?.length && (
            <Tooltip
              content={
                <ul className={styles.tooltipList}>
                  {benefit.reasonsIneligible.map((reason) => (
                    <li key={reason}>{reason}</li>
                  ))}
                </ul>
              }
              side="left"
              delayDuration={50}
            >
              <button type="button">
                <FeatherIcon name="AlertTriangle" size={18} strokeWidth={'2.5'} color={colors.coral} />
              </button>
            </Tooltip>
          )}
        </DefDescription>
      );
    })}
  </DefList>
);

export default IneligibleBenefitsSection;
